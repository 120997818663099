import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HubConnectionBuilder} from "@microsoft/signalr";

const root = ReactDOM.createRoot(document.getElementById('root'));

const connection = new HubConnectionBuilder()
    .withUrl('https://test1.testbern.com/hub')
    .build();
connection.start()
    .then(() => console.log('Connected to SignalR hub'))
    .catch(err => console.error('Error connecting to hub:', err));

connection.on('ReceiveMessage', message => {
    console.log('Received message:', message);
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
